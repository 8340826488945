import * as React from 'react';
import styled from 'styled-components';
import { Redirect, Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import loadable from '@loadable/component';
import { Menu, Footer } from '@pm/gui';

import { SiteContextProvider } from './utils/context/SiteContext';
import { GlobalStyles } from '../client/styles/global';

import { Seo } from './utils/seo/Index';
import { PageLoaderFallback } from './components/layout/PageLoaderFallback';
import { useMenuHide } from './utils/hooks/useMenuHide';

import { ApolloWrapper } from './components/wrapper/Apollowrapper';
import { QuizTemplate_V3 } from './components/containers/quiz/graphql/Index_v3';

const Home = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/home/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const AboutUs = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/AboutUs'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Office = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/office/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Documents = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/object/Documents'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const GlobalSearchPage = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/GlobalSearch'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Stillingsannonser = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/stillinger/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const BrokerOfficeSearch = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/BrokerOffice'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Boligbytte = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/Boligbytte'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Kjop = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/kjop/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Klikk = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/klikk/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Voice = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/voice/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Valuation = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/Valuation'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Financing = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/financing/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Quiz = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/quiz/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Fritid = loadable(() => import('./components/containers/fritid/index'));

const DigitalMeeting = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/digitalmeeting/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Verdi = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/verdi'),
  {
    fallback: <PageLoaderFallback />
  }
);

const SlikForegaarEnVerdivurdering = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/SlikForegaarEnVerdivurdering'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Eiendomsmegler = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/eiendomsmegler/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Dagensboligmarked = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/Dagensboligmarked'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const ValuationSignatur = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/Signatur'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Boligsok = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/boligsok/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Broker = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/broker/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const RegisterInterest = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/interested/RegisterInterest'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Boliglan = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/boliglaan/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Kampanje = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/kampanje'),
  {
    fallback: <PageLoaderFallback />
  }
);
const MeglerDraft = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/meglercv/MeglerDraft'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const Content = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/content/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const MellomLinjene = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/kampanje/mellomlinjene'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Prisliste = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/prisliste'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Solgtpris = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/solgtpris/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const NoMatch = loadable(
  () => import(/* webpackPrefetch: true */ './components/containers/404Old'),
  {
    fallback: <PageLoaderFallback />
  }
);

const InformasjonBg = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/kampanje/vikebo/InformasjonBg'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Privacy = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/privacy/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Delivery = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/delivery/Index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const SoldDM = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/solddm/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const SelgeKjopeBank = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/nordea/index'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const InformasjonStvg = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/kampanje/stavanger/InformasjonStvg'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const ValuationGeneral = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/General'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const MaripaaVeien = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/maripaaveien/MaripaaVeien'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const Bekkelaget = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/bekkelaget/Bekkelaget'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const NordstrandsDagene = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/nordstrandsdagene/Nordstrandsdagene'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Saedberg = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/saedberg/Saedberg'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const Fjellsiden = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/fjellsiden/Fjellsiden'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const Sandviken = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/sandviken/Sandviken'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const VeienTilSolgt = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/eiendomsmegler/veientilsolgt/VeienTilSolgt'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const Solgte = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/solgte/Solgte'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Boligutvikling2024 = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/boligutvikling/Boligutvikling'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);
const StylingKlargjoring = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/stylingklargjoring/StylingKlargjoring'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const SearchEstates = loadable(
  () =>
    import(/* webpackPrefetch: true */ './components/containers/search/Index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const Budrunden = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/budrunden/Budrunden'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

const PostHogTesting = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './components/containers/valuation/posthog/route'
    ),
  {
    fallback: <PageLoaderFallback />
  }
);

export const App: React.FC = () => {
  const client = useApolloClient();
  const hideMenu = useMenuHide();

  return (
    <Wrapper>
      <GlobalStyles />
      <Seo
        title="PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Søk blant våre eiendommer til salgs"
        image=""
        url=""
      />
      <SiteContextProvider>
        <Route
          path="/:p1?/:p2?/:p3?/:p4?"
          render={(props) => {
            if (
              (props?.match?.params?.p1 === 'prisliste' &&
                props?.match?.params?.p2?.match(/\d+/)) ||
              hideMenu
            ) {
              return null;
            } else {
              return <Menu className="header" client={client} />;
            }
          }}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              if (props.location.search.match(/q=/gi)) {
                return <GlobalSearchPage {...props} />;
              } else if (props.location.pathname === '/') {
                return <Home {...props} />;
              }
            }}
          />
          <Route exact path="/personvern" component={Privacy} />
          <Route
            exact
            path="/stillinger/:slug?"
            component={Stillingsannonser}
          />
          <Route exact path="/maripaaveien" component={MaripaaVeien} />
          <Route exact path="/skipanesvegen264" component={Fjellsiden} />
          <Route exact path="/sandviken" component={Sandviken} />
          <Route
            exact
            path="/paamelding/boligutvikling2024"
            component={Boligutvikling2024}
          />
          <Route exact path="/kontorer" component={BrokerOfficeSearch} />
          <Route exact path="/budrunden" component={Budrunden} />
          <Route path="/om-oss" component={AboutUs} />
          <Route
            exact
            path="/boligbytte-selge-eiendom"
            component={Boligbytte}
          />
          <Route exact path="/kjope-eiendom" component={Kjop} />
          <Route exact path="/solgt/:sguid" component={SoldDM} />
          {/* Redirect old links with path /verdivurdering to new path */}
          <Redirect exact from="/kjop" to="/kjope-eiendom" />
          <Redirect exact from="/boligbytte" to="/boligbytte-selge-eiendom" />
          <Redirect exact from="/fritid" to="/selge-fritidseiendom-hytte" />
          <Redirect
            exact
            from="/verdivurdering"
            to="/verdivurdering-selge-eiendom"
          />
          <Redirect
            exact
            from="/:urltag/verdivurdering"
            to="/:urltag/verdivurdering-selge-eiendom"
          />
          <Redirect
            exact
            from="/megler/:path/verdivurdering"
            to="/megler/:path/verdivurdering-selge-eiendom"
          />
          <Redirect exact from="/finansiering" to="/finansiering-boliglan" />
          <Redirect
            exact
            from="/leveransebeskrivelse"
            to="/leveransebeskrivelse-selge-bolig"
          />
          <Redirect
            exact
            from="/leveransebeskrivelse/:page"
            to="/leveransebeskrivelse-selge-bolig/:page"
          />
          {/*< AB-testing >*/}
          <Route
            exact
            path="/verdivurdering-selge-eiendom"
            component={PostHogTesting}
          />
          {/*</ AB-testing >*/}
          <Route
            exact
            path="/:urltag/verdivurdering-selge-eiendom"
            component={Valuation}
          />
          <Route exact path="/finansiering-boliglan" component={Financing} />
          <Route exact path="/selge-fritidseiendom-hytte" component={Fritid} />
          <Route exact path="/quiz/:area/:urltag" component={QuizTemplate_V3} />
          <Route exact path="/quiz/:type?" component={Quiz} />
          <Route
            exact
            path="/digitalt-mote-eiendom-salg"
            component={DigitalMeeting}
          />
          <Route exact path="/:sguid/solgtkampanje" component={Solgte} />
          <Route exact path="/solgt" component={VeienTilSolgt} />

          <Route
            /* path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)" */
            path="/verdivurdering-boligkalkulator"
            component={Verdi}
          />
          <Route path="/eiendomsmegler" component={Eiendomsmegler} />
          <Route exact path="/boligsok" component={Boligsok} />
          <Route
            path="/megler/draft/:path"
            render={(props: any) => {
              return (
                <ApolloWrapper>
                  <MeglerDraft {...props} />
                </ApolloWrapper>
              );
            }}
          />
          <Route exact path="/megler/:path/:p1(kontakt)?" component={Broker} />
          <Route
            exact
            path="/megler/:path/verdivurdering-selge-eiendom"
            component={Valuation}
          />
          <Route exact path="/selgekjope-bank" component={SelgeKjopeBank} />
          <Route
            exact
            path="/:page(registrerinteressentobjekt|registrerinteressent|meldinteresse|onsker-budvarsel)/:sguid"
            component={RegisterInterest}
          />
          <Route path="/kampanje" component={Kampanje} />
          <Route
            path="/styling-og-klargjoring"
            component={StylingKlargjoring}
          />

          <Route
            path="/leveransebeskrivelse-selge-bolig"
            component={Delivery}
          />
          <Route exact path="/informasjon_bg" component={InformasjonBg} />
          <Route exact path="/informasjon_stvg" component={InformasjonStvg} />

          <Route path="/content" component={Content} />
          <Route path="/mellomlinjene" component={MellomLinjene} />
          <Route exact path="/prisliste/:id" component={Prisliste} />
          <Route exact path="/prisliste/:id/preview" component={Prisliste} />
          <Route exact path="/finner-ikke-side" component={NoMatch} />
          <Route
            exact
            path="/:urltag(\w+)/:sguid/:permalink/solgtpris"
            component={Solgtpris}
          />
          <Route
            exact
            path={'/search'}
            render={(props: any) => {
              return (
                <ApolloWrapper>
                  <SearchEstates {...props} />
                </ApolloWrapper>
              );
            }}
          />
          <Route
            exact
            path={'/kjope-bolig'}
            render={(props: any) => {
              return (
                <ApolloWrapper>
                  <SearchEstates {...props} />
                </ApolloWrapper>
              );
            }}
          />
          <Route exact path="/:urltag/:p1?/:p2?/:p3?" component={Office} />
          <Route exact path="/solgtpris/:sguid" component={Solgtpris} />
          <Route component={NoMatch} />
        </Switch>
        <Footer />
      </SiteContextProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
